// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React , { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'



const getSummaryPrice = (items) => {
  console.log("getSummaryPrice ", items)
  return items
    .map(x => parseFloat(x.price))
    .reduce((a, b) => a + b, 0);
}

const convertNumber = (floatNumber) => {
  const stringNumber = Number.parseFloat(floatNumber).toFixed(2);
  return stringNumber.replace('.', ',');
}

const Cart = props => {
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    setCartItems(props.cartItems)
    setTotalPrice(getSummaryPrice(props.cartItems))
  }, [props]);

  const handleDelete = (index, id) => {
    const confirmBox = window.confirm(
      "Haluatko poistaa?"
    )
    if (confirmBox === true) {
      let thisCartItems = JSON.parse(localStorage.getItem('cartItems'));
      thisCartItems.splice(index, 1);
      localStorage.setItem('cartItems', JSON.stringify(thisCartItems));
      setCartItems(thisCartItems)
      setTotalPrice(getSummaryPrice(thisCartItems))
    }
  }


  return (
    <div className="text-left">
      {cartItems.map((item, idx) => (
        <div className="row row_order" key={idx}>
          <div className="col-2">
            {item.picture && <img width="60" src={item.picture}/>}
          </div>
          <div className="col-8">
            {item.name} <b>{item.size_option && item.size_option}</b>
            <br/>
            {(item.insertItems && item.insertItems.length > 0 ) &&
              <>
                {item.insertItems.map((v, idx) => (
                  <span className="side-order" key={`plus_${idx}`}>
                    <i className="fas fa-plus-circle green-color mr-1"></i> { v.text}
                  </span>
                ))}
                <br/>
              </>
            }
            {(item.removeItems && item.removeItems.length > 0 ) &&
              <>
                {item.removeItems.map((v, idx) => (
                  <span className="side-order" key={`minus_${idx}`}>
                    <i className="fas fa-minus-circle red-color mr-1"></i> { v.text}
                  </span>
                ))}
                <br/>
              </>
            }
            {item.footNote &&
              <>
                <span className="side-order">
                  <i className="fas fa-pencil-alt"></i> {item.footNote}
                </span>
                <br/>
              </>
            }
          </div>
          <div className="col-2 text-right">
            {convertNumber(item.price)} €
            <br/>
            <button
              className="btn btn-danger btn-sm check_local"
              onClick={() => handleDelete(idx, item.id)} >
              <i className="fas fa-trash-alt"></i>
            </button>
          </div>
          <div className="col-12"><hr/></div>
        </div>
      ))}
      <div className="text-right font-weight-bold cover_total_price">
        {(cartItems.length > 1) ? `${cartItems.length}  tuotetta, ` : (cartItems.length > 0) ? `${cartItems.length} tuote, `: ''}
        yhteensä {convertNumber(totalPrice)} €
      </div>
      <hr/>
    </div>)
}

document.addEventListener('turbolinks:load', () => {
  const cartElement = document.getElementById('local_cart')
  if (document.getElementById('cart_modal')) {
    document.getElementById('cart_modal').addEventListener('show.bs.modal', function (event) {
      console.log("show.bs.modal")
      ReactDOM.render(
        <Cart cartItems={JSON.parse(localStorage.getItem(`cartItems`))}/>, cartElement
      )
    })
  } else if (document.getElementById('local_cart')) {
    ReactDOM.render(
      <Cart cartItems={JSON.parse(localStorage.getItem(`cartItems`))}/>, cartElement
    )
  }

})

